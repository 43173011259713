import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import { useCart, useProduct } from 'frontastic';
import { PDP_PRODUCT_ADDED_TO_CART } from 'helpers/constants/events';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { isStringHtml } from 'helpers/utils/isStringHtml';
import { useParams, useRouter } from 'next/navigation';
import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { Product } from 'shared/types/product';
import { UIProduct } from '../../../commercetools-ui/organisms/product/product-details/types';
import useGetUserInfo from 'helpers/hooks/useGetUserInfo';
export interface MoreInfoProps {
  product: UIProduct;
}

interface MoreInfoType {
  title: string;
  body: string;
  'cta-label': string;
  'cta-link': string;
  'product-skus': string[];
  'padi-analytics'?: string;
}

const getMoreInfoData = (data: string) => {
  try {
    const {
      title,
      body,
      'padi-analytics': padiAnalytics,
      'cta-label': ctaLabel,
      'cta-link': ctaLink,
      'product-skus': productSkus,
    }: MoreInfoType = data ? JSON.parse(data) : {};
    return { title, body, ctaLabel, ctaLink, productSkus, padiAnalytics };
  } catch (error) {
    console.error('Error parsing moreInfo:', error);
    return {};
  }
};

const RelatedProduct: React.FC<{ product: Product }> = ({ product }) => {
  const { locale } = useParams();
  const variant = product.variants[0];
  const imgUrl = variant.images?.[0];
  const name = product.name;
  const { formatMessage } = useFormat({ name: 'cart' });
  const priceLabel =
    product?.productType === 'subscription'
      ? formatMessage({ id: 'subscription.club.term.label', defaultMessage: 'year' })
      : null;
  return (
    <div className="mb-30 flex gap-16">
      {imgUrl && <img className="max-w-[40%] rounded-[6px]" src={imgUrl} />}
      <div>
        <p className="font-semibold">{name}</p>
        <Typography className="mt-0 font-normal">
          {CurrencyHelpers.formatForCurrency(variant.price as number, locale) + (priceLabel ? ' / ' + priceLabel : '')}
        </Typography>
      </div>
    </div>
  );
};

const RelatedProducts: React.FC<{ products: Product[] }> = ({ products }) => {
  const components: React.JSX.Element[] = [];
  products.forEach((product, index) => {
    if (index > 0) {
      components.push(<hr className="mb-20" />);
    }
    components.push(<RelatedProduct product={product} />);
  });
  return <>{components}</>;
};

const AddToCartButton: React.FC<{ products: Product[]; ctaLabel: string; padiAnalytics?: string }> = ({
  products,
  ctaLabel,
  padiAnalytics,
}) => {
  const [addingProducts, setAddingProducts] = useState(false);
  const { addItem } = useCart();
  const quantity = 1;
  const router = useRouter();
  const openCart = () => {
    router.push('/cart');
  };

  const handleAddToCart = async () => {
    setAddingProducts(true);
    try {
      await Promise.all(products.map((product) => addItem(product, product.variants[0], quantity)));

      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      console.error('Error adding products:', error);
    } finally {
      setAddingProducts(false);
      openCart();
    }
  };

  return (
    <Button
      className="mt-16 w-full rounded-md border !border-[#D8D8DA] text-14 font-medium text-padi-blue"
      variant="secondary"
      onClick={handleAddToCart}
      loading={addingProducts}
      dataAnalytics={PDP_PRODUCT_ADDED_TO_CART}
      padi-analytics={padiAnalytics}
    >
      {ctaLabel}
    </Button>
  );
};

const Upsell: FC<MoreInfoProps> = ({ product }) => {
  const { getUserClubEntitlments } = useGetUserInfo();
  const moreInfoData = product?.variants[0].attributes?.['section-13'];
  const router = useRouter();
  const { getProductBySku } = useProduct();
  const [loading, setLoading] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState<Product[]>([]);
  const [isActiveClubMember, setIsActiveClubMember] = useState<boolean>(true);
  const { title, body, ctaLabel, ctaLink, productSkus, padiAnalytics } = useMemo(
    () => getMoreInfoData(moreInfoData),
    [moreInfoData],
  );
  const [allProducts, setAllProducts] = useState<Product[]>([product]);

  const validateActiveClubMember = useCallback(async () => {
    const clubEntitlements = await getUserClubEntitlments();
    const isMember = clubEntitlements && clubEntitlements.length > 0;
    if (!isMember) setIsActiveClubMember(false);
    return isMember;
  }, [getUserClubEntitlments]);

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      if (!productSkus?.length) {
        return;
      }

      setLoading(true);
      try {
        const isMember = await validateActiveClubMember();
        if (!isMember) {
          const results = await Promise.all(productSkus.map(getProductBySku));
          setLoading(false);
          setRelatedProducts(results);
          setAllProducts([product, ...results]);
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchRelatedProducts();
  }, [productSkus]);

  const truncateText = (text: string | undefined, maxLength: number) => {
    if (text && text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  const openLink = () => {
    router.push(ctaLink ?? '');
  };

  return (
    <>
      {!isActiveClubMember && (
        <div className="my-20 rounded-md border border-gray-200 bg-padi-gray-lighter px-16 pb-16 pt-20	">
          <RelatedProducts products={relatedProducts} />
          {title && <p className="font-semibold">{truncateText(title, 70)}</p>}
          {body && isStringHtml(body) ? (
            <div className="mt-16 text-14" dangerouslySetInnerHTML={{ __html: body }}></div>
          ) : (
            body && <Markdown className="mt-16 text-14" markdown={truncateText(body, 185) || ''} />
          )}
          {!productSkus?.length ? (
            <Button
              className="mt-16 w-full rounded-md border !border-[#D8D8DA] text-14 font-medium text-padi-blue"
              variant="secondary"
              onClick={openLink}
              padi-analytics={padiAnalytics}
            >
              {ctaLabel}
            </Button>
          ) : (
            loading == false && (
              <AddToCartButton products={allProducts} ctaLabel={ctaLabel} padiAnalytics={padiAnalytics} />
            )
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(Upsell);
